import * as _util2 from "util";

var _util = "default" in _util2 ? _util2.default : _util2;

import * as _passthrough2 from "readable-stream/passthrough";

var _passthrough = "default" in _passthrough2 ? _passthrough2.default : _passthrough2;

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var util = _util;
var PassThrough = _passthrough;
exports = {
  Readable: Readable,
  Writable: Writable
};
util.inherits(Readable, PassThrough);
util.inherits(Writable, PassThrough); // Patch the given method of instance so that the callback
// is executed once, before the actual method is called the
// first time.

function beforeFirstCall(instance, method, callback) {
  instance[method] = function () {
    delete instance[method];
    callback.apply(this || _global, arguments);
    return (this || _global)[method].apply(this || _global, arguments);
  };
}

function Readable(fn, options) {
  if (!((this || _global) instanceof Readable)) return new Readable(fn, options);
  PassThrough.call(this || _global, options);
  beforeFirstCall(this || _global, "_read", function () {
    var source = fn.call(this || _global, options);

    var emit = (this || _global).emit.bind(this || _global, "error");

    source.on("error", emit);
    source.pipe(this || _global);
  });
  this.emit("readable");
}

function Writable(fn, options) {
  if (!((this || _global) instanceof Writable)) return new Writable(fn, options);
  PassThrough.call(this || _global, options);
  beforeFirstCall(this || _global, "_write", function () {
    var destination = fn.call(this || _global, options);

    var emit = (this || _global).emit.bind(this || _global, "error");

    destination.on("error", emit);
    this.pipe(destination);
  });
  this.emit("writable");
}

export default exports;
const _Readable = exports.Readable,
      _Writable = exports.Writable;
export { _Readable as Readable, _Writable as Writable };